@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@300;400;500;600;700&display=swap');


@import '~antd/dist/antd.less';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  -webkit-font-smoothing: antialiased;
  color: black;
}
body,
button {
  font-family: 'Fira Sans', sans-serif;
}
#root {
  margin: 0 0;
  min-height: 100vh;
}
button {
  cursor: pointer;
}

// Fonts

// @font-face {
//   font-family: 'WhyteInktrap';
//   src: url('../assets/fonts/WhyteInktrap-Black.otf') format('truetype');
//   font-weight: 800;
//   font-style: normal;
// }

// Layout

.ant-layout {
  min-height: 100vh;
}

// Inputs
.ant-input,
.ant-input-affix-wrapper {
  border: none;
  border-radius: 0;
}
.ant-input-affix-wrapper-focused {
  border: none;
  border-radius: 0;
  box-shadow: none;
}

.modal-create .ant-input-affix-wrapper {
  background-color: #2b5740;
}

.modal-create .ant-input {
  background-color: #2b5740;
  color: white;
}

.instrument-picker .ant-picker-input input {
  color: white !important;
  width: 100vw;
}

.instrument-picker .ant-picker-input ::placeholder {
  color: white !important;
  opacity: 0.8;
}
.instrument-picker-edit .ant-picker-input input {
  color: black !important;
  width: 100vw;
}

.instrument-picker-edit .ant-picker-input ::placeholder {
  color: black !important;
  opacity: 0.8;
}

.ant-input-search-icon::before {
  position: absolute;
  top: 0;
  bottom: 0;
  display: block;
  border-left: none;
  transition: all 0.3s;
  content: '';
}

.ant-radio-button {
  &-wrapper,
  &-wrapper:first-child,
  &-wrapper:last-child {
    border-radius: 9px;
    margin-left: 10px;
    margin-bottom: 10px;
    border-width: 1px;

    &::before {
      display: none !important;
    }
  }
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-weight: 600 !important;
  color: black !important;
}

.ant-form-item-label label {
  margin-left: 11px !important;
}

.modal-create .ant-radio-button {
  border: 1px solid white;
  border-radius: 9px;
  background-color: #2b5740;
  color: white;
  z-index: -1;
}

.instrument-radio
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #333 !important;
  opacity: 100% !important;
  outline: none !important;
}
.instrument-radio .ant-radio-button-wrapper {
  height: 25px !important;
  outline: none !important;
}

.instrument-radio .ant-radio-button-wrapper .ant-radio-button-wrapper:focus {
  outline: none !important;
  text-decoration: none;
}

.instrument-radio
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  box-shadow: none !important;
}

.modal-edit .ant-radio-button {
  border: 1px solid black;
  border-radius: 9px;
  background-color: white;
  color: black;
  z-index: -1;
}

.modal-edit .ant-radio-button-wrapper > span:nth-child(2) {
  color: black;
  font-size: 1.1em;
}

.modal-create .ant-radio-button-wrapper > span:nth-child(2) {
  color: white;
  font-size: 1.1em;
}

.modal-create .ant-radio-button-wrapper {
  background-color: #2b5740;
}

.modal-create .ant-form-item-label label {
  color: white;
  font-size: 1.2em;
}

.modal-create .ant-radio-button-checked {
  border: 3px solid white;
}

::placeholder {
  color: black !important;
  opacity: 0.4;
}

.ant-input-number-input {
  border-top: none;
  border-right: none;
  border-bottom: 2px solid #dfdfe0;
  border-left: none;
}

//Data display
.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-topLeft > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-topRight > .ant-popover-content > .ant-popover-arrow {
  bottom: 6.2px;
  border-top-color: black;
  border-right-color: black;
  border-bottom-color: black;
  border-left-color: black;
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
}

.ant-popover-inner-content {
  padding: 0 1em 0.25em;
  color: #1a1a1a;
}

.ant-table-body {
  min-height: 68vh !important;
}

.ant-table-thead .ant-table-cell {
  font-weight: bold;
  font-size: 1.1em;
}

.ant-table-title {
  background-color: rgb(240, 241, 245);
}

//Feedback
.modal-create .ant-modal-content {
  background-color: #2b5740;
}

.modal-create .ant-card {
  background-color: white;
}

.ant-modal-header {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  border: 0;
}

.modal-create .ant-modal-header {
  background-color: #2b5740;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  border: 0;
}

.modal-create .ant-modal-footer {
  border: 0;
}

.modal-create .ant-modal-close {
  color: white;
}

.modal-create .ant-modal-title {
  color: white;
  font-weight: 600;
  font-size: 1.8em;
  line-height: 2em;
}

.ant-modal-close-x {
  font-size: 1.2rem !important;
  display: block;
  background-color: #ffcf8f !important;
  border-radius: 100%;
  width: 35px;
  height: 35px;
  position: relative;
  top: 40px !important;
  right: 40px !important;
}

.mapboxgl-popup {
  z-index: 1;

  animation: fadeIn ease 0.5s;
  -webkit-animation: fadeIn ease 0.5s;
  -moz-animation: fadeIn ease 0.5s;
  -o-animation: fadeIn ease 0.5s;
  -ms-animation: fadeIn ease 0.5s;

  @keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-moz-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-webkit-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-o-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-ms-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
}

.mapboxgl-popup-tip {
  display: none !important;
}

.mapboxgl-popup-content {
  width: 10rem;
}

.mapboxgl-popup-close-button {
  display: none;
}

.zoomImageModal {
  width: calc(100vw - 4rem) !important;
  height: calc(100vh - 4rem) !important;

  & .ant-modal-content {
    box-shadow: none !important;
    background-color: transparent !important;
    width: 100%;
    height: 100%;
  }

  & .ant-modal-body {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    & img {
      cursor: initial;
    }
  }

  & .ant-modal-close {
    & svg {
      font-size: 1.5rem !important;
    }
  }
}
@primary-color: #FFCF8F;@primary-light-color: #FFCF8F99;@secondary-color: #F7951C;@salmon-color: #F5C1A7;@body-background: #FFFFFF;@success-color: #009B90;@warning-color: #AE8F0C;@error-color: #D94848;@heading-color: #000000;@text-color: #000000;@text-color-secondary: #707070;@box-shadow-base: 20px 20px 40px #00000029;@font-family: undefined;@border-radius-base: 9px;